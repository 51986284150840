import "semantic-ui-css/semantic.min.css";
import "react-toastify/dist/ReactToastify.css";
import "@itm21st/faceted-filter/dist/style.css";
import "./styles/styles.scss";
import "./styles/kendo.scss";

import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { User, useAuth0 } from "@auth0/auth0-react";

import AuthError from "components/AuthError";
import CatchAllRedirect from "components/CatchAllRedirect";
import Layout from "components/Layout";
import Loader from "components/Loader";
import { Role } from "services/Constants";

const AddCase = lazy(() => import("./features/case/add-case/AddCase"));
const Broker = lazy(() => import("./features/broker/BrokerContextProvider"));
const BrokerList = lazy(() => import("./features/broker/BrokerContextProvider"));
const Buyer = lazy(() => import("./features/buyer/BuyerContextProvider"));
const BuyerList = lazy(() => import("./features/buyer/BuyerContextProvider"));
const CarrierDetails = lazy(() => import("./features/carrier/CarrierDetails"));
const CarrierList = lazy(() => import("./features/carrier/CarrierList"));
const CaseDetails = lazy(() => import("./features/case/CaseContextProvider"));
const CaseSearch = lazy(() => import("./features/case-search/CaseSearch"));
const DiseaseList = lazy(() => import("./features/disease/DiseaseList"));
const DocumentTemplateManagement = lazy(() => import("./features/document-template-management/DocumentTemplateManagement"));
const EscrowAgents = lazy(() => import("./features/escrow-agent/EscrowAgents"));
const Fraud = lazy(() => import("./features/fraud/Fraud"));
const InsuredList = lazy(() => import("./features/insured/InsuredList"));
const LegalLog = lazy(() => import("./features/legal-log/LegalLogContextProvider"));
const LegalMatrix = lazy(() => import("./features/legal-matrix/LegalMatrix"));
const Notes = lazy(() => import("./features/my-notes/MyNotes"));
const Officer = lazy(() => import("./features/officer/OfficerContextProvider"));
const OfficerList = lazy(() => import("./features/officer/OfficerContextProvider"));
const OwnerList = lazy(() => import("./features/owner/OwnerList"));
const PrivateRoute = lazy(() => import("components/PrivateRoute"));
const Producer = lazy(() => import("./features/producer/ProducerContextProvider"));
const ProducerList = lazy(() => import("./features/producer/ProducerContextProvider"));
const ReportDashboard = lazy(() => import("./features/report-dashboard/ReportDashboard"));
const RollbackHistory = lazy(() => import("./features/rollback-history/RollbackHistory"));
const StateList = lazy(() => import("./features/states/StatesContextProvider"));
const States = lazy(() => import("./features/states/StatesContextProvider"));
const ToDo = lazy(() => import("./features/todo/ToDo"));
const Trustee = lazy(() => import("./features/trustee/Trustee"));
const Users = lazy(() => import("./features/users/Users"));

const defaultClaimCheck = (_user: User, roles: Role[]) => {
    // The Fraud role is special and indicates that a user is ONLY a fraud app user
    // Since a user must have the Casetrack AD group to get access to the app, we only need to check roles here
    return !roles.some((role) => role === Role.Fraud);
};

const contractorClaimCheck = (_user: User, roles: Role[]) => {
    // Access to the /contractor set of routes
    return roles.some((role) => role === Role.Fraud);
};

const App: React.FC = () => {
    const { isLoading, error } = useAuth0();
    if (isLoading) {
        return <Loader />;
    }

    if (error) {
        return <AuthError error={error} />;
    }

    return (
        <Suspense fallback={<Loader />}>
            <Routes>
                <Route path="/" element={<PrivateRoute component={Layout} claimCheck={defaultClaimCheck} />}>
                    {/* This is the main app that all normal CasetrackUsers will access */}
                    <Route path="" element={<CaseSearch />} />
                    <Route path="add-case" element={<AddCase />} />
                    <Route path="broker" element={<BrokerList />} />
                    <Route path="broker/:BrokerID" element={<Broker />} />
                    <Route path="buyer" element={<BuyerList />} />
                    <Route path="buyer/:BuyerID" element={<Buyer />} />
                    <Route path="carrier" element={<CarrierList />} />
                    <Route path="carrier/:id" element={<CarrierDetails />} />
                    <Route path="case/:id/*" element={<CaseDetails />} />
                    <Route path="disease" element={<DiseaseList />} />
                    <Route path="document-template-management" element={<DocumentTemplateManagement />} />
                    <Route path="escrow-agents" element={<EscrowAgents />} />
                    <Route path="fraud" element={<Fraud />} />
                    <Route path="insured" element={<InsuredList />} />
                    <Route path="legal-log" element={<LegalLog />} />
                    <Route path="legal-log/:LegalLogID" element={<LegalLog />} />
                    <Route path="legal-matrix" element={<LegalMatrix />} />
                    <Route path="notes" element={<Notes />} />
                    <Route path="officer" element={<OfficerList />} />
                    <Route path="officer/:OfficerID" element={<Officer />} />
                    <Route path="owner" element={<OwnerList />} />
                    <Route path="producer" element={<ProducerList />} />
                    <Route path="producer/:ProducerID" element={<Producer />} />
                    <Route path="report" element={<ReportDashboard />} />
                    <Route path="rollback-history" element={<RollbackHistory />} />
                    <Route path="search" element={<CaseSearch />} />
                    <Route path="states" element={<StateList />} />
                    <Route path="states/:StateID" element={<States />} />
                    <Route path="to-do" element={<ToDo />} />
                    <Route path="trustee/:CasesID/:TrustPersonIndexID" element={<Trustee />} />
                    <Route path="users" element={<Users />} />
                </Route>
                <Route path="/contractor" element={<PrivateRoute component={Layout} claimCheck={contractorClaimCheck} />}>
                    {/* This is a special sub-application that is only available for contractors who have the FraudUser role */}
                    <Route path="fraud" element={<Fraud />} />
                </Route>
                <Route path="/" element={<CatchAllRedirect />} />
                <Route path="*" element={<CatchAllRedirect />} />
            </Routes>
        </Suspense>
    );
};

export default App;
